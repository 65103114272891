import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useDispatch } from '../../contexts/ResumeContext';
import BaseModal from '../BaseModal';
import Button from '../../components/shared/Button';
import ModalContext from '../../contexts/ModalContext';

const LoadDemoModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { emitter, events } = useContext(ModalContext);

  useEffect(() => {
    const unbind = emitter.on(events.LOADDEMO_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleLoadDemoData = () => {
    dispatch({ type: 'load_demo_data' });
  };

  return (
    <BaseModal
      hideActions
      state={[open, setOpen]}
      title={t('builder.actions.loadDemoData.heading')}
    >
      <div>
        <p className="leading-loose">
          {t('builder.actions.loadDemoData.text')}
        </p>

        <Button className="mt-5" onClick={handleLoadDemoData}>
          {t('builder.actions.loadDemoData.button')}
        </Button>
      </div>
    </BaseModal>
  );
};

export default memo(LoadDemoModal);
